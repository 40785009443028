import { FC, ReactElement } from "react"

const AgreementLinks: FC = (): ReactElement => (
  <section className="font-body text-center text-sm font-normal">
    By clicking ‘Get Started’, you agree to DrWell’s{" "}
    <a
      href="https://drwell.com/terms-and-conditions/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <b className="font-semibold text-sm accent-gray-charcoal cursor-pointer">
        Terms and Conditions
      </b>
    </a>{" "}
    and{" "}
    <a
      href="https://www.drwell.com/privacy-policy/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <b className="font-semibold text-sm accent-gray-charcoal cursor-pointer">
        Privacy Policy
      </b>
    </a>
    .
    <p className="text-xs font-normal font-body accent-gray-slate mt-[1.31rem] text-left">
      By entering your number, you agree to receive text messages from DrWell at
      the number provided. Consent is not a condition of purchase. Message
      frequency varies. Message and data rates may apply. You can unsubscribe at
      any time by replying STOP. Text HELP to get help.
    </p>
  </section>
)

export default AgreementLinks
